import { useState } from "react";
import { useStore } from "../../store/Store";
import { useSnackbar } from "notistack";
import { useNavigate } from "react-router-dom";

import { useTranslation } from "react-i18next";

// import { toJS } from "mobx";

// const PostLoginForm = async (values, successCallback, errorCallback) => {
// do stuff
// if successful
// };

const initialFormValues = {
  email: "",
  password: "",
  formSubmitted: false,
  success: false,
};

export const useFormControls = () => {
  const store = useStore();
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation();

  const [values, setValues] = useState(initialFormValues);
  const [errors, setErrors] = useState({});
  const [loading, setLoading] = useState(false);

  const validate = (fieldValues = values) => {
    let temp = { ...errors };

    if ("email" in fieldValues) {
      temp.email = fieldValues.email ? false : t("this_field_is_required");
      if (fieldValues.email)
        temp.email = /^[^@\s]+@[^@\s]+\.[^@\s]+$/.test(fieldValues.email)
          ? false
          : t("email_is_not_valid");
    }

    if ("password" in fieldValues)
      temp.password = fieldValues.password
        ? false
        : t("this_field_is_required");

    setErrors({
      ...temp,
    });
    // console.log("errors: ", errors)
  };

  const handleInputValue = (e) => {
    const { name, value } = e.target;
    setValues({ ...values, [name]: value });
    // console.log("values: ", values);

    validate({ [name]: value });
  };

  // const handleSuccess = () => {
  //   setValues({
  //     ...initialFormValues,
  //     formSubmitted: true,
  //     success: true,
  //   });
  // };

  // const handleError = () => {
  //   setValues({
  //     ...initialFormValues,
  //     formSubmitted: true,
  //     success: false,
  //   });
  // };

  const formIsValid = (fieldValues = values) => {
    const isValid =
      fieldValues.email &&
      fieldValues.password &&
      Object.values(errors).every((x) => x === false);

    return isValid;
  };

  const resetPassword = async () => {
    //console.log(errors.email);
    if (errors.email) {
      enqueueSnackbar(t("email_is_not_valid"), { variant: "error" });
    } else {
      //console.log(values.email);
      if (values.email !== "") {
        await apiResetPass();
      }
    }
  };

  const apiResetPass = async () => {
    const apiURL = process.env.REACT_APP_API_URL + "new_pass_mail.php";
    const bodyData = {
      email: values.email,
    };
    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Request-Headers": "Content-Type, Authorization",
      },
      body: JSON.stringify(bodyData),
    };

    setLoading(true);

    fetch(apiURL, requestOptions)
      .then((response) => response.json())
      .then((data) => {
        //console.log("php return data: ", data);
        if (data.status === "OK") {
          enqueueSnackbar(t("email_sent"), { variant: "info" });
        } else {
          enqueueSnackbar(t("email_not_sent"), { variant: "error" });
        }
        //console.log(data);
      });

    setTimeout(() => {
      setLoading(false);
    }, 5000);
  };

  const handleFormSubmit = async (e) => {
    e.preventDefault();
    const isValid =
      Object.values(errors).every((x) => x === false) && formIsValid();

    if (isValid) {
      // await PostLoginForm(values, handleSuccess, handleError);

      await apiLogin();
    }
  };


  const apiLogin = async () => {
    const bodyData = {
      email: values.email,
      password: values.password,
    };

    const requestOptions = {
      method: "POST",
      // mode: "no-cors",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(bodyData),
      redirect: "follow",
    };

    const apiURL = process.env.REACT_APP_API_URL + "/sif/users/login";
    // const apiURL = "https://rest.fleetnet.online/api/sif/users/login";

    setLoading(true);

    fetch(apiURL, requestOptions)
      .then((response) => response.json())
      .then((data) => {
        if (!data.success) {
          // variant could be success, error, warning, info, or default
          // enqueueSnackbar(data.response.result, { variant: "error" });
          enqueueSnackbar(t("email_or_password_is_incorrect"), {
            variant: "error",
          });
        } else {
          store.updateIsLogged(true);
          store.user.email = values.email;
          store.user.role = data.role;
          // store.user.username = data.response.result.username;
          store.user.token = data.token;
          // store.user.announceText = data.response.result.announceText;
          store.user.announceText = "Testni announce text koji se pojavljuje po potrebi i trenutno traje 10 min a kontrolisan je u LoginFormControls.js";
          // console.log(toJS(store.user));
          setTimeout(() => {
            store.user.announceText = "";
          }, 10 * 60 * 1000);

          navigate("/");
        }
      });

    setLoading(false);
  };

  return {
    values,
    errors,
    loading,
    handleInputValue,
    handleFormSubmit,
    formIsValid,
    resetPassword,
  };
};
