import styled from "styled-components";

const Announcement = (props) => {
  const { text } = props;

  const AnnounContainer = styled.div`
    background-color: yellow;
    opacity: 0.7;
    color: black;
    display: flex;
    align-items: center;
    justify-content: center;
    font-style: 14px;
    font-weight: 500;
    height: 20px;
    position: absolute;
    top: 55px;
    width: 94%;
    margin-left: 3%;
    margin-right: 3%;
    border-radius: 6px;
    overflow: hidden;
    z-index: 100;
  `;
  const AnnounText = styled.div`
    text-align: right;
    white-space: nowrap;

    /* animation properties */
    -moz-transform: translateX(100%);
    -webkit-transform: translateX(100%);
    transform: translateX(100%);

    -moz-animation: my-animation 15s linear infinite;
    -webkit-animation: my-animation 15s linear infinite;
    animation: my-animation 15s linear infinite;

    /* for Firefox */
    @-moz-keyframes my-animation {
      from {
        -moz-transform: translateX(100%);
      }
      to {
        -moz-transform: translateX(-100%);
      }
    }

    /* for Chrome */
    @-webkit-keyframes my-animation {
      from {
        -webkit-transform: translateX(100%);
      }
      to {
        -webkit-transform: translateX(-100%);
      }
    }

    @keyframes my-animation {
      from {
        -moz-transform: translateX(100%);
        -webkit-transform: translateX(100%);
        transform: translateX(100%);
      }
      to {
        -moz-transform: translateX(-100%);
        -webkit-transform: translateX(-100%);
        transform: translateX(-100%);
      }
    }
  `;

  return text ? (
    <AnnounContainer>
      <AnnounText>{text}</AnnounText>
    </AnnounContainer>
  ) : null;
};

export default Announcement;
