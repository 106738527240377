import Drawer from "@mui/material/Drawer";
import Grid from "@mui/material/Grid";
import Collapse from "@mui/material/Collapse";
import List from "@mui/material/List";
import ListItemText from "@mui/material/ListItemText";
import { Link, useLocation } from "react-router-dom";
import { Divider, IconButton, ListItemButton, Typography } from "@mui/material";
import { useState } from "react";

import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import { useStore } from "../store/Store";
import { Person } from "@mui/icons-material";
import { observer } from "mobx-react-lite";
import { Fragment } from "react";

import { useTranslation } from "react-i18next";

const DrawerNavigate = observer(({ ...props }) => {
  const store = useStore();
  const location = useLocation();
  const { t } = useTranslation();

  const { open, setOpen } = props;
  const [expandTopLevel, setExpandTopLevel] = useState({});

  const menuItems = [
    {
      title: "registries",
      itemList: [
        {
          descript: "departments",
          link: "/sif/departments",
          banned: ["user"],
        },
        {
          descript: "job_positions",
          link: "/sif/jobpositions",
          banned: ["user"],
        },
        {
          descript: "cost_centres",
          link: "/sif/costcentres",
          banned: ["user"],
        },
        {
          descript: "users",
          link: "/sif/users",
          banned: ["admin", "user"],
        },
        { descript: "separator", link: "", banned: [] },
        {
          descript: "vehicle_categories",
          link: "/sif/vehicle_categories",
          banned: ["user"],
        },
        {
          descript: "vehicle_data",
          link: "/sif/vehicle_data",
          banned: ["user"],
        },
        { descript: "vehicles", link: "/sif/vehicles", banned: ["user"] },
        { descript: "separator", link: "", banned: [] },
        { descript: "clients", link: "/sif/clients", banned: ["user"] },
        //   { descript: "separator", link: "", banned: [] },
        //   { descript: "Test", link: "/test", banned: [""] },
        //   { descript: "Test", link: "/test", banned: [""] },
      ],
    },
    {
      title: "records",
      itemList: [
        { descript: "Test", link: "/test", banned: [""] },
        { descript: "Test", link: "/test", banned: [""] },
        { descript: "Test", link: "/test", banned: [""] },
        { descript: "Test", link: "/test", banned: [""] },
        { descript: "Test", link: "/test", banned: [""] },
      ],
    },
    {
      title: "view",
      itemList: [
        { descript: "statistics", link: "/statistics", banned: ["user"] },
        // { descript: "complete_view", link: "/completeview", banned: [""] },
        { descript: "Test", link: "/test", banned: [""] },
        { descript: "Test", link: "/test", banned: [""] },
        { descript: "Test", link: "/test", banned: [""] },
        { descript: "Test", link: "/test", banned: [""] },
        { descript: "Test", link: "/test", banned: [""] },
        { descript: "Test", link: "/test", banned: [""] },
        { descript: "Test", link: "/test", banned: [""] },
        { descript: "Test", link: "/test", banned: [""] },
        { descript: "Test", link: "/test", banned: [""] },
        { descript: "Test", link: "/test", banned: [""] },
        { descript: "Test", link: "/test", banned: [""] },
        { descript: "Test", link: "/test", banned: [""] },
        { descript: "Test", link: "/test", banned: [""] },
        { descript: "Test", link: "/test", banned: [""] },
        { descript: "Test", link: "/test", banned: [""] },
        { descript: "Test", link: "/test", banned: [""] },

        { descript: "Test", link: "/test", banned: [""] },
      ],
    },
    {
      title: "statistics",
      itemList: [
        { descript: "statistics", link: "/statistics", banned: ["user"] },
        // { descript: "complete_view", link: "/completeview", banned: [""] },
        { descript: "Test", link: "/test", banned: [""] },
        { descript: "Test", link: "/test", banned: [""] },
        { descript: "Test", link: "/test", banned: [""] },
        { descript: "Test", link: "/test", banned: [""] },
        { descript: "Test", link: "/test", banned: [""] },
        { descript: "Test", link: "/test", banned: [""] },
        { descript: "Test", link: "/test", banned: [""] },
      ],
    },
  ];

  const sxStyle = {
    height: "36px",
    width: "200px",
    mx: 2,
    color: store.isDarkTheme ? "#cccccc" : "#111111",
    "&:hover": {
      color: store.isDarkTheme ? "yellow" : "blue",
      cursor: "pointer",
    },
  };

  const closeSlow = () => {
    const timeout = setTimeout(() => {
      setOpen(false);
    }, 1000);

    return () => clearTimeout(timeout);
  };

  function MenuPerson() {
    return (
      <Grid
        container
        direction="row"
        alignItems="center"
        onClick={() => setOpen(false)}
      >
        <Person sx={{ fontSize: 90, mx: "auto" }} />
      </Grid>
    );
  }

  const expandMenu = (name) => {
    setExpandTopLevel((prevState) => {
      const nextState = {};
      Object.keys(expandTopLevel).forEach((key) => {
        nextState[key] = false;
      });
      // console.log("nextState", { ...nextState, [name]: !prevState[name] });
      return { ...nextState, [name]: !prevState[name] };
      // return { ...prevState, [name]: true };
    });
  };

  function MenuUser() {
    return (
      <Typography
        variant="h6"
        fontWeight="bold"
        sx={{
          color: store.isDarkTheme ? "yellow" : "blue",
          mx: "auto",
          mb: 1,
        }}
      >
        {store.isLogged ? store.user.username : "User not logged in"}
      </Typography>
    );
  }

  const menuList = { itemGroup: menuItems };

  return !store.isLogged ? null : (
    <Grid
      container
      justifyContent="flex-start"
      alignItems="center"
      sx={{
        transition: "all 1s",
        transitionTimingFunction: "ease-in-out",
      }}
    >
      <Grid item>
        <Drawer
          // {...props}
          open={open}
          onClose={() => setOpen(false)}
          anchor={"left"}
        >
          <MenuPerson />
          <MenuUser />

          <Divider />

          {menuList.itemGroup.map((topLevel, index) => (
            <Fragment key={1000 + index}>
              <Typography
                id="basic-list-demo"
                level="body3"
                textTransform="uppercase"
                fontWeight="bold"
                sx={{ ...sxStyle, marginLeft: 0 }}
                // onClick={() => setExpandTopLevel({ [topLevel.title]: true })}
                onClick={() => expandMenu(topLevel.title)}
              >
                <IconButton aria-label="expand" size="small">
                  {expandTopLevel[topLevel.title] ? (
                    <KeyboardArrowUpIcon />
                  ) : (
                    <KeyboardArrowDownIcon />
                  )}
                </IconButton>
                {t(topLevel.title)}
              </Typography>

              <Collapse in={expandTopLevel[topLevel.title]} timeout="auto">
                {topLevel.itemList.map((secondLevel, index) => (
                  <Fragment key={1100 + index}>
                    {secondLevel.descript === "separator" ? (
                      <Divider variant="middle" />
                    ) : (
                      <List disablePadding>
                        {/* <List dense disablePadding={true}> */}
                        <ListItemButton
                          // button={true}
                          selected={secondLevel.link === location.pathname}
                          disabled={secondLevel.banned.some((element) => {
                            return (
                              element.toLowerCase() ===
                              store.user.role.toLowerCase()
                            );
                          })}
                          onClick={() => closeSlow()}
                          sx={{
                            ...sxStyle,
                            marginLeft: 2,
                            color:
                              secondLevel.link === location.pathname
                                ? store.isDarkTheme
                                  ? "yellow"
                                  : "blue"
                                : "inherited",
                          }}
                        >
                          <Link
                            style={{
                              textDecoration: "none",
                              color: "inherit",
                            }}
                            to={secondLevel.link}
                          >
                            <ListItemText primary={t(secondLevel.descript)} />
                          </Link>
                        </ListItemButton>
                      </List>
                    )}
                  </Fragment>
                ))}
              </Collapse>

              <Divider style={{ marginTop: 12 }} />
            </Fragment>
          ))}
        </Drawer>
      </Grid>
    </Grid>
  );
});

export default DrawerNavigate;
