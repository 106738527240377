import React from "react";
import ReactDOM from "react-dom/client";

import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import Backend from "i18next-http-backend";

import "./index.css";
import App from "./App";

const translationRS = require("./assets/translationRS.json");
const translationDE = require("./assets/translationDE.json");
const translationFR = require("./assets/translationFR.json");
const translationGB = require("./assets/translationGB.json");

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .use(LanguageDetector)
  .use(Backend)
  .init({
    supportedLngs: ["rs", "gb", "de", "fr"],
    fallbackLng: "rs",
    detection: {
      order: ["path", "cookie", "htmlTag", "localStorage", "subdomain"],
      caches: ["cookie"],
    },
    // backend: {
    //   loadPath: "./locales/{{lng}}/translation.json",
    // },
    //react: { useSuspense: false },
    debug: false,
    resources: {
      rs: { translation: translationRS },
      de: { translation: translationDE },
      fr: { translation: translationFR },
      gb: { translation: translationGB },
    },
  });

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(<App />);
