import { lazy, Suspense } from "react";
import { observer } from "mobx-react-lite";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { CssBaseline } from "@mui/material";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";

import { RootStore, StoreProvider } from "./store/Store";

import { SnackbarProvider } from "notistack";

import Announcement from "./components/Announcement";
import AppBarAvatar from "./components/AppBarAvatar";
import Login from "./pages/Login";
import About from "./pages/About";
// import Test from "./pages/Test";

import CircularProgress from "@mui/material/CircularProgress";

import { lightTheme, darkTheme } from "./common/consts";

const Home = lazy(() => import("./pages/Home"));
const SifDepartments = lazy(() => import("./pages/sif/Departments"));
const SifJobPositions = lazy(() => import("./pages/sif/JobPositions"));
const SifCostCentres = lazy(() => import("./pages/sif/CostCentres"));
const SifUsers = lazy(() => import("./pages/sif/Users"));
const VehicleCategories = lazy(() => import("./pages/sif/VehicleCategories"));
const VehicleData = lazy(() => import("./pages/sif/VehicleData"));
const Vehicles = lazy(() => import("./pages/sif/Vehicles"));
const Clients = lazy(() => import("./pages/sif/Clients"));

// const Statistics = lazy(() => import("./pages/Statistics"));
// const LogBooks = lazy(() => import("./pages/LogBooks"));
// const Earnings = lazy(() => import("./pages/Earnings"));
// const MonthlyView = lazy(() => import("./pages/MonthlyView"));
// const EarningView = lazy(() => import("./pages/EarningView"));
// const UserTableTemplate = lazy(() => import("./components/UserTableTemplate"));

const store = new RootStore();

const App = observer(() => {
  let theme = store.isDarkTheme
    ? createTheme(darkTheme)
    : createTheme(lightTheme);

  // this become mobix State
  // const announceText = "";
  // const announceText = "Zakup aplikacije istice za 22 dana. Molimo Vas da u doglednom vremenu izvrsite uplatu.";

  return (
    <>
      <StoreProvider store={store}>
        <SnackbarProvider maxSnack={3} autoHideDuration={5000}>
          <ThemeProvider theme={theme}>
            <CssBaseline />

            <Suspense fallback={<CircularProgress />}>
              <Router>
                <Announcement text={store.user.announceText} />
                <AppBarAvatar />

                {!store.isLogged ? (
                  <Login />
                ) : (
                  // <Home />
                  <Routes>
                    <Route path="/home" element={<Home />} />
                    <Route
                      path="/sif/departments"
                      element={<SifDepartments />}
                    />
                    <Route
                      path="/sif/jobpositions"
                      element={<SifJobPositions />}
                    />
                    <Route
                      path="/sif/costcentres"
                      element={<SifCostCentres />}
                    />
                    <Route path="/sif/users" element={<SifUsers />} />
                    <Route
                      path="/sif/vehicle_categories"
                      element={<VehicleCategories />}
                    />
                    <Route path="/sif/vehicle_data" element={<VehicleData />} />
                    <Route path="/sif/vehicles" element={<Vehicles />} />
                    <Route path="/sif/clients" element={<Clients />} />

                    <Route path="/login" element={<Login />} />
                    <Route exact path="/" element={<Home />} />

                    {/* time line ------------------------------*/}

                    {/* <Route path="/workinghours" element={<LogBooks />} /> */}
                    {/* <Route path="/earnings" element={<Earnings />} /> */}

                    {/* <Route path="/monthlyview" element={<MonthlyView />} /> */}
                    {/* <Route path="/earningview" element={<EarningView />} /> */}
                    {/* <Route path="/statistics" element={<Statistics />} /> */}
                    {/* <Route path="/completeview" element={<Contact />} /> */}
                    {/* <Route path="/test" element={<Test />} /> */}

                    <Route path="/about" element={<About />} />
                    {/* <Route path="/contact/test" element={<Test />} /> */}
                    {/* <Route path="/usertable" element={<UserTableTemplate />} /> */}

                    <Route path="*" element={<Home />} />
                  </Routes>
                )}
              </Router>
            </Suspense>
          </ThemeProvider>
        </SnackbarProvider>
      </StoreProvider>
    </>
  );
});

export default App;
